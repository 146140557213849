import axios from "@axios";
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {


        saveWithdrawRequest(ctx, withdrawData) {
            
            return new Promise((resolve, reject) => {
                axios
                    .post("/withdraw_leave_request", withdrawData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    })
            });
        },

        deleteLeaveApplication(ctx, withdrawData) {
            
            return new Promise((resolve, reject) => {
                axios
                    .post("/delete_leave_application", withdrawData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    })
            });
        },

        fetchLeaveApplications(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/leave_applications", { params: queryParams })
                    .then((response) => {       
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
        fetchLeaveApplicationStatus() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/leave_applications-status")
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
        fetchLeaveApplication(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`leave_applications/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        fetchLeaveBalance(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`user_balances`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        fetchUserHolidays(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/holidays", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
        fetchNHRs(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/hire-request", {
                        params: {
                            perPage: 100,
                            page: 0,
                        },
                    })
                    .then((response) => {
                        resolve(response.data.data);
                    })
                    .catch((error) => reject(error));
            });
        },
        fetchLeaveList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/leaves", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
        addRecruitertoNHR(ctx, userData) {
            console.log(userData);
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        `hire-request/${userData.nhrId}/assign-to-bba/${userData.recruiter}`
                    )
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        addLeaveApplicationtoNHR(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `hire-request/${userData.nhrId}/assign-leave_applications`,
                        {
                            leave_applications: userData.leave_applications,
                        }
                    )
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            } else {
                                reject(error.response.data.message);
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        addLeaveApplication(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("leave_applications", userData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            } else {
                                reject(error.response.data.message);
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },

        saveReview(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("leave_applications/saveReview", userData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },

        updateLeaveApplication(ctx, leave_application) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `leave_applications/${leave_application.id}`,
                        leave_application.data,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    )
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        // updateLeaveApplicationStatus(ctx, leave_application) {
        //     return new Promise((resolve, reject) => {
        //         axios
        //             .post(`leave_applications/statusUpdate/${leave_application.id}`, {
        //                 status: 1
        //             })
        //             .then(response => {
        //                 resolve(response);
        //             });
        //     });
        // },
        removeLeaveApplication(ctx, userId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`leave_applications/${userId}`)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        removeMultipleLeaveApplications(ctx, ids) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/deleteMultipleLeaveApplications/`, { ids: ids })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        fetchLeaveApplicationSource(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("leave_applications-source", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
        addSource(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post("leave_applications-source", {
                        name: queryParams.name,
                    })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        removeSource(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`leave_applications-source/${queryParams.id}`)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
        getCustomFields() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/leave_applications/create")
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
        addDesignation(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/designations", { name: queryParams.name })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        fetchDesignationsList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/designations", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
    },
};
