<template>
    <div>
        <!-- Filters -->
        <app-collapse hover>
            <app-collapse-item title="Filters">
                <leave-application-list-filters
                    :status-filter.sync="statusFilter"
                    :status-options="leaveApplicationStatusOption"
                    :created-by-filter.sync="createdByFilter"
                    :employee-options="createdByFilterOptions"
                    :manager-filter.sync="managerFilter"
                    :manager-options="managerOptions"
                    :leave-type-filter.sync="leaveTypeFilter"
                    :leave-type-options="leaveTypeOptions"
                />
            </app-collapse-item>
        </app-collapse>
        <!-- Table Container Card -->
        <b-card no-body class="mb-0 mt-2">
            <div class="m-1">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="4"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                        <b-form-checkbox
                            v-if="
                                leaveApplicationsMetaData.length &&
                                $can('Zircly Admin')
                            "
                            class="mr-2"
                            v-model="isDeleteMultipleCandChecked"
                        >
                        </b-form-checkbox>
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            :searchable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>entries</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="8">
                        <div
                            class="d-flex align-items-center justify-content-end"
                        >
                            <b-input-group
                                v-if="selectedLeaveApplications.length == 0"
                                class="input-group-merge"
                            >
                                <b-input-group-prepend is-text>
                                    <feather-icon icon="SearchIcon" />
                                </b-input-group-prepend>
                                <b-form-input
                                    v-model="searchQuery"
                                    class="d-inline-block mr-1"
                                    placeholder="Search Name"
                                />
                            </b-input-group>
                            <b-button
                                v-if="
                                    $can('leave_application_create') &&
                                    selectedLeaveApplications.length == 0
                                "
                                variant="primary"
                                :to="{ name: 'add-leave-application' }"
                                class="mr-1"
                            >
                                <span class="text-nowrap d-none d-md-flex"
                                    >Apply for Leave</span
                                >
                                <span class="text-nowrap d-md-none">Apply</span>
                            </b-button>
                            <b-button
                                pill
                                v-b-modal.modal-review-application
                                v-if="
                                    $can('Zircly Admin') &&
                                    leaveApplicationsDeleteCount != 0
                                "
                                class="float-right"
                                variant="info"
                                :disabled="leaveApplicationsDeleteCount == 0"
                            >
                                <span class="text-nowrap"
                                    >Review
                                    {{ leaveApplicationsDeleteCount }} Leave
                                    Application(s)</span
                                >
                            </b-button>

                            <b-modal
                                id="modal-review-application"
                                ref="modalReviewApplication"
                                hide-footer
                                centered
                                @hidden="resetModal"
                            >
                                <template #modal-header="{ close }">
                                    <h2 class="text-center">
                                        Review Applications
                                    </h2>
                                    <feather-icon
                                        class="cursor-pointer"
                                        icon="XIcon"
                                        size="32"
                                        @click="close()"
                                    />
                                </template>

                                <div v-if="itemHashId != ''" class="mb-2">
                                    <h2>
                                        {{ itemUsername }}
                                    </h2>
                                    <div>{{ itemDates }}</div>
                                    <div class="mt-1">
                                        <span class="h4 text-dark"
                                            >Reason:</span
                                        >
                                        {{ itemReason }}
                                    </div>
                                </div>

                                <form
                                    ref="form"
                                    @submit.stop.prevent="handleSubmit"
                                    class=""
                                >
                                    <b-form-group
                                        label="Remark"
                                        label-for="remark-input"
                                        invalid-feedback="Remark is required"
                                        :state="nameState"
                                    >
                                        <b-form-textarea
                                            id="remark-input"
                                            v-model="reviewer_reason"
                                            :state="nameState"
                                            placeholder="Remark"
                                            required
                                            rows="4"
                                        ></b-form-textarea>
                                    </b-form-group>

                                    <div
                                        v-if="itemHashId == ''"
                                        class="d-flex justify-content-center"
                                    >
                                        <b-button
                                            pill
                                            variant="success"
                                            class="mr-1"
                                            :disabled="reviewer_reason == ''"
                                            v-on:click="
                                                saveMassReview('Approved')
                                            "
                                        >
                                            Accept
                                        </b-button>
                                        <b-button
                                            pill
                                            variant="danger"
                                            :disabled="reviewer_reason == ''"
                                            v-on:click="
                                                saveMassReview('Rejected')
                                            "
                                        >
                                            Reject
                                        </b-button>
                                    </div>
                                    <div
                                        v-if="itemHashId != ''"
                                        class="d-flex justify-content-center"
                                    >
                                        <b-button
                                            pill
                                            variant="success"
                                            class="mr-1"
                                            :disabled="reviewer_reason == ''"
                                            v-on:click="saveReview('Approved')"
                                        >
                                            Accept
                                        </b-button>
                                        <b-button
                                            pill
                                            variant="danger"
                                            :disabled="reviewer_reason == ''"
                                            v-on:click="saveReview('Rejected')"
                                        >
                                            Reject
                                        </b-button>
                                    </div>
                                </form>
                            </b-modal>

                            <b-modal
                                id="modal-withdraw-application"
                                ref="modalWithdrawApplication"
                                hide-footer
                                centered
                                @hidden="resetModal"
                            >
                                <div v-if="itemHashId != ''" class="mb-2">
                                    <h2>
                                        {{ itemUsername }}
                                    </h2>
                                    <div>{{ itemDates }}</div>
                                    <div class="mt-1">
                                        <span class="h4 text-dark"
                                            >Reason:</span
                                        >
                                        {{ itemReason }}
                                    </div>
                                </div>

                                <template #modal-header="{ close }">
                                    <h2 class="text-center">
                                        Withdraw Applications
                                    </h2>
                                    <feather-icon
                                        class="cursor-pointer"
                                        icon="XIcon"
                                        size="24"
                                        @click="close()"
                                    />
                                </template>

                                <form
                                    ref="form"
                                    @submit.stop.prevent="handleSubmit"
                                    class=""
                                >
                                    <b-form-group
                                        label="Reason"
                                        label-for="remark-input"
                                        invalid-feedback="Reason is required"
                                        :state="nameState"
                                    >
                                        <b-form-textarea
                                            id="remark-input"
                                            v-model="withdraw_reason"
                                            :state="nameState"
                                            placeholder="Withdraw Reason"
                                            required
                                            rows="4"
                                        ></b-form-textarea>
                                    </b-form-group>
                                    <div class="d-flex justify-content-center">
                                        <b-button
                                            pill
                                            variant="danger"
                                            class="mr-1"
                                            @click="close()"
                                        >
                                            Cancel
                                        </b-button>
                                        <b-button
                                            pill
                                            variant="success"
                                            class="mr-1"
                                            :disabled="withdraw_reason == ''"
                                            @click="saveWithdraw(itemLeaveId)"
                                        >
                                            Withdraw
                                        </b-button>
                                    </div>
                                </form>
                            </b-modal>

                            <b-modal
                                id="modal-delete-application"
                                ref="modalDeleteApplication"
                                hide-footer
                                centered
                                @hidden="resetModal"
                            >
                                <template #modal-header="{ close }">
                                    <h2 class="text-center">
                                        Delete Leave Applications
                                    </h2>
                                    <feather-icon
                                        class="cursor-pointer"
                                        icon="XIcon"
                                        size="24"
                                        @click="close()"
                                    />
                                </template>

                                <form
                                    ref="form"
                                    @submit.stop.prevent="handleSubmit"
                                    class=""
                                >
                                <div>
                                    <p>Are you sure delete this leave application ?</p>
                                </div>
                                <div class="d-flex justify-content-center">
                                        <b-button
                                            pill
                                            variant="danger"
                                            class="mr-1"
                                            @click="close()"
                                        >
                                            Cancel
                                        </b-button>
                                        <b-button
                                            pill
                                            variant="success"
                                            class="mr-1"
                                            @click="deleteLeaveApplication()"
                                        >
                                            Delete
                                        </b-button>
                                    </div>
                                </form>
                            </b-modal>
                        </div>

                        <b-button
                            v-if="$can('leave_application_delete') && false"
                            class="float-right"
                            variant="danger"
                            :disabled="leaveApplicationsDeleteCount == 0"
                            @click="confirmDeleteMultipleRecords"
                        >
                            <span class="text-nowrap"
                                >Withdraw
                                {{ leaveApplicationsDeleteCount }} Leave
                                Application(s)</span
                            >
                        </b-button>
                    </b-col>
                </b-row>
            </div>

            <b-form-checkbox-group
                id="checkbox-group"
                v-model="selectedLeaveApplications"
                name="selectedLeaveApplications"
            >
                <b-table
                    ref="refUserListTable"
                    class="position-relative"
                    :items="fetchLeaveApplicationList"
                    responsive
                    :fields="tableColumns"
                    primary-key="id"
                    :sort-by.sync="sortBy"
                    show-empty
                    empty-text="No matching records found"
                    :sort-desc.sync="isSortDirDesc"
                >
                    <template #cell(select)="data">
                        <div class="text-nowrap text-center1">
                            <b-form-checkbox
                                :value="data.item.hashid"
                                class="ml-1"
                            >
                            </b-form-checkbox>
                        </div>
                    </template>
                    <!-- Column: User -->
                    <template #cell(user.name)="data">
                        <b-media vertical-align="center">
                            <b-link
                                v-if="$can('leave_application_show')"
                                :to="{
                                    name: 'view-leave-application',
                                    params: { id: data.item.hashid },
                                }"
                                class="font-weight-bold d-inline-block text-nowrap"
                                v-b-tooltip.hover.v-default
                                :title="data.item.user.name"
                            >
                                {{ data.item.user.name | str_limit(15) }}
                            </b-link>
                            <span
                                v-else
                                v-b-tooltip.hover.v-default
                                :title="data.item.name"
                            >
                                {{ data.item.user.name | str_limit(15) }}
                            </span>
                        </b-media>
                    </template>

                    <!--Column: Status -->
                    <template #cell(status)="data">
                        <b-badge
                            pill
                            :variant="`light-${resolveUserStatusVariant(
                                data.item.status
                            )}`"
                            class="text-capitalize"
                        >
                            {{ data.item.status_label }}
                        </b-badge>
                    </template>

                    <template #cell(from_date)="data">
                        {{ data.item.from_date | formatDate }}
                    </template>
                    <template #cell(dates)="data">
                        <span
                            v-b-tooltip.hover.v-default
                            :title="data.item.dates | formatDatesArray"
                        >
                            {{
                                data.item.dates
                                    | formatDatesArray
                                    | str_limit(26)
                            }}
                        </span>
                    </template>
                    <template #cell(to_date)="data">
                        {{ data.item.to_date | formatDate }}
                    </template>

                    <!-- Column: Actions -->
                    <template #cell(action)="data">
                        <feather-icon
                            @click="editRecord(data)"
                            v-if="
                                ($can('Zircly Admin') || isCreator(data)) &&
                                $can('leave_application_edit')
                            "
                            v-b-tooltip.hover
                            title="Edit"
                            icon="EditIcon"
                            size="16"
                            class="mr-1 cursor-pointer"
                        />
                        <feather-icon
                            @click="viewRecord(data)"
                            v-if="
                                $can('Zircly Admin') ||
                                (isCreator(data) &&
                                    $can('leave_application_show')) ||
                                data.item.reviewer.hashid == auth_user.hashid
                            "
                            v-b-tooltip.hover
                            title="View"
                            icon="EyeIcon"
                            size="16"
                            class="cursor-pointer"
                        />

                        <b-dropdown
                            v-if="
                                data.item &&
                                (($can('leave_application_edit') &&
                                    (data.item.reviewer.hashid ==
                                        auth_user.hashid ||
                                        $can('Zircly Admin'))) ||
                                    ($can('leave_application_delete') &&
                                        data.item.user.hashid ==
                                            auth_user.hashid &&
                                        data.item.status != '1'))
                            "
                            variant="link"
                            size="sm"
                            toggle-class="text-decoration-none"
                            no-caret
                        >
                            <template v-slot:button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="text-body align-middle mr-25"
                                />
                            </template>

                            <b-dropdown-item
                                class="btn-sm p-0"
                                v-b-modal.modal-review-application
                                v-if="
                                    $can('leave_application_edit') &&
                                    (data.item.reviewer.hashid ==
                                        auth_user.hashid ||
                                        $can('Zircly Admin'))
                                "
                                @click="confirmReview(data)"
                            >
                                <feather-icon
                                    icon="MessageSquareIcon"
                                    size="13"
                                    class="mr-50"
                                />
                                <span>Review</span>
                            </b-dropdown-item>

                            <b-dropdown-item
                                v-b-modal.modal-withdraw-application
                                class="btn-sm p-0"
                                @click="confirmDeleteRecord(data)"
                                v-if="
                                    $can('leave_application_delete') &&
                                    data.item.status_label != 'Approval Pending'&&
                                    data.item.user.hashid == auth_user.hashid

                                    // && data.item.status != '1'
                                "
                            >
                                <feather-icon
                                    icon="ArrowDownCircleIcon"
                                    size="13"
                                    class="mr-50"
                                />
                                <span>Withdraw</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                                class="btn-sm p-0"
                                @click="confirmDeleteLeave(data)"
                                v-if="
                                    $can('leave_application_delete') &&
                                    data.item.status_label == 'Approval Pending' &&
                                    data.item.user.hashid == auth_user.hashid
                                    // && data.item.status != '1'
                                "
                            >
                                <feather-icon
                                    icon="TrashIcon"
                                    size="13"
                                    class="mr-50"
                                />
                                <span>Delete</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </b-table>
            </b-form-checkbox-group>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted"
                            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                            {{ dataMeta.of }} entries</span
                        >
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-if="selectedLeaveApplications.length == 0"
                            v-model="currentPage"
                            :total-rows="totalLeaveApplicationList"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                        <b-button
                            v-else-if="
                                $can('leave_application_delete') && false
                            "
                            class="float-right"
                            variant="danger"
                            :disabled="leaveApplicationsDeleteCount == 0"
                            @click="confirmDeleteMultipleRecords"
                        >
                            <span class="text-nowrap"
                                >Delete {{ leaveApplicationsDeleteCount }} Leave
                                application</span
                            >
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BButton,
    BCard,
    BCol,
    BFormInput,
    BLink,
    BMedia,
    BPagination,
    BRow,
    BTable,
    VBTooltip,
    BFormCheckbox,
    BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import leaveApplicationStoreModule from "../leaveApplicationStoreModule";
import useLeaveApplicationList from "./useLeaveApplicationList";
import LeaveApplicationListFilters from "./LeaveApplicationListFilters.vue";
import moment from "moment";
var globalVariable;

export default {
    components: {
        LeaveApplicationListFilters,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BLink,
        BPagination,
        AppCollapse,
        AppCollapseItem,
        vSelect,
        /* eslint-disable */
        ToastificationContent,
        /* eslint-enable */
        BFormCheckbox,
        BFormCheckboxGroup,
    },
    directives: {
        "b-tooltip": VBTooltip,
    },
    setup() {
        const REFERRAL_APP_STORE_MODULE_NAME = "app-leave_applications";
        const isDeleteMultipleCandChecked = ref(false);
        const selectedLeaveApplications = ref([]);
        const leaveApplicationsDeleteCount = ref(0);

        // Register module
        if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
            store.registerModule(
                REFERRAL_APP_STORE_MODULE_NAME,
                leaveApplicationStoreModule
            );
        }

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
                store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
        });

        const statusOptions = [
            { label: "In Progress", code: 0 },
            { label: "Selected", code: 1 },
            { label: "Rejected", code: 2 },
        ];
        const {
            fetchLeaveApplicationList,
            tableColumns,
            perPage,
            currentPage,
            totalLeaveApplicationList,
            leaveApplicationsMetaData,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            statusFilter,
            managerFilter,
            refetchData,
            resolveUserStatusVariant,
            leaveApplicationStatusOption,
            createdByFilter,
            leaveTypeFilter,
            leaveTypeOptions,
        } = useLeaveApplicationList();
        watch(isDeleteMultipleCandChecked, (val) => {
            selectedLeaveApplications.value = [];
            if (val) {
                leaveApplicationsMetaData.value.forEach((leave_application) => {
                    selectedLeaveApplications.value.push(
                        leave_application.hashid
                    );
                });
            }
        });

        watch(selectedLeaveApplications, (val) => {
            leaveApplicationsDeleteCount.value = val.length;
        });

        return {
            // Sidebar
            fetchLeaveApplicationList,
            tableColumns,
            perPage,
            currentPage,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            totalLeaveApplicationList,
            refUserListTable,
            statusFilter,
            managerFilter,
            selectedLeaveApplications,
            leaveApplicationsDeleteCount,
            isDeleteMultipleCandChecked,
            refetchData,
            resolveUserStatusVariant,
            leaveApplicationStatusOption,
            createdByFilter,
            leaveApplicationsMetaData,
            leaveTypeFilter,
            leaveTypeOptions,
        };
    },
    data() {
        return {
            auth_user: this.$cookies.get("userData"),
            createdByFilterOptions: [],
            managerOptions: [],
            reviewer_reason: "",
            itemUsername: "",
            itemDates: "",
            itemHashId: "",
            itemReason: "",
            nameState: null,
            withdraw_reason: "",
        };
    },
    created() {
        const self = this;
        if (!this.$can("Zircly Admin")) {
            this.tableColumns = this.tableColumns.filter(
                (item) => item.key != "select"
            );
            //this.fetchTeamList();
        }
        const urlParams = new URLSearchParams(window.location.search);
        const userId = urlParams.get('employee_hash_id');
        this.createdByFilter = userId ? userId : ""
        this.fetchEmployeeList();
        this.fetchManagerList();
    },
    methods: {
        resetModal() {
            const self = this;
            self.itemUsername = "";
            self.itemDates = "";
            self.itemHashId = "";
            self.itemReason = "";
            self.reviewer_reason = "";
        },
        close() {
            this.$refs.modalWithdrawApplication.hide();
        },
        formatDatesArray: function (value) {
            if (value) {
                var myStringArray = value;
                var outputStringArray = [];
                for (var i = 0; i < myStringArray.length; i++) {
                    outputStringArray.push(
                        moment(String(myStringArray[i])).format("MMM Do YYYY")
                    );
                }
                var outputString = outputStringArray.join(", ");
                return outputString;
            }
        },
        fetchManagerList() {
            this.$store
                .dispatch("app/descendantMangers")
                .then((res) => {
                    this.managerOptions = res.data.data;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        fetchEmployeeList() {
            this.$store
                .dispatch("app/fetchEmployeeList")
                .then((res) => {
                    this.createdByFilterOptions = res.data.data;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        fetchTeamList() {
            this.$store
                .dispatch("app/fetchTeamList")
                .then((res) => {
                    if (res.data.data.length > 0) {
                        this.createdByFilterOptions = res.data.data;
                    }
                })
                .catch((err) => {
                    // this.$toast({
                    //     component: ToastificationContent,
                    //     props: {
                    //         title: "Fetching Team List failed.",
                    //         icon: "BellIcon",
                    //         variant: "danger",
                    //         text: err.toString(),
                    //     },
                    // });
                    this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fetching Team List failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                });
        },
        isCreator(data) {
            if (data.item.user.hashid == this.auth_user.hashid) {
                return true;
            }
            return false;
        },
        editRecord(data) {
            this.$router
                .push(`/edit/leave-application/${data.item.hashid}`)
                .catch(() => {});
        },
        viewRecord(data) {
            this.$router
                .push(`/view/leave-application/${data.item.hashid}`)
                .catch(() => {});
        },
        confirmDeleteLeave(data){
            globalVariable = data.item.leave.id;

                this.$refs.modalDeleteApplication.show();

                const self = this;

                self.itemUsername = data.item.user.name;
                self.itemDates = this.formatDatesArray(data.item.dates);

                self.itemHashId = data.item.hashid;
                self.itemReason = data.item.reason;
                self.itemLeaveId = data.item.leave.id;
        },
        deleteLeaveApplication(){
        
        const formData = new FormData();
        formData.append("leave_id", this.itemHashId);
        this.$store
            .dispatch(
                "app-leave_applications/deleteLeaveApplication",
                formData
            )
            .then((res) => {
                if (res.data.success) {
                    console.log("ok")
                    this.$refs.modalDeleteApplication.hide();
                    this.refetchData();
                    // this.$toast({
                    //     component: ToastificationContent,
                    //     props: {
                    //         title: "Deleted successfully",
                    //         icon: "BellIcon",
                    //         variant: "success",
                    //         text: res.data.message,
                    //     },
                    // });
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Deleted successfully',
                            icon: 'BellIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                        }, {
                        timeout: 2000, 
                        position: 'bottom-center',
                        toastClassName:"radius-8 successful-info",
                        hideProgressBar : true,
                        });
                } else {
                    // this.$toast({
                    //     component: ToastificationContent,
                    //     props: {
                    //         title: "Deleted Failed",
                    //         icon: "BellIcon",
                    //         variant: "danger",
                    //         text: res.data.message,
                    //     },
                    // });
                    this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Deleted Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
            })
            .catch((error) => {
                console.log(error)
                // this.$toast({
                //     component: ToastificationContent,
                //     props: {
                //         title: "Already Deleted",
                //         icon: "BellIcon",
                //         variant: "danger",
                //         text: error.response.data.message,
                //     },
                // });
                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Already Deleted"',
                icon: 'BellIcon',
                variant: 'danger',
                text:  error.response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            });
    },
        confirmDeleteRecord(data) {
            console.log(data.item);

            globalVariable = data.item.leave.id;

            this.$refs.modalWithdrawApplication.show();

            const self = this;

            self.itemUsername = data.item.user.name;
            self.itemDates = this.formatDatesArray(data.item.dates);

            self.itemHashId = data.item.hashid;
            self.itemReason = data.item.reason;
            self.itemLeaveId = data.item.leave.id;
        },

        saveWithdraw() {
            // return
            const formData = new FormData();
            formData.append("withdraw_reason", this.withdraw_reason);
            formData.append("leave_id", this.itemHashId);
            // formData.append("leave_id", itemLeaveId);

            // Call the Vuex store action
            this.$store
                .dispatch(
                    "app-leave_applications/saveWithdrawRequest",
                    formData
                )
                .then((res) => {
                    if (res.data.success) {
                        this.$refs.modalWithdrawApplication.hide();
                        this.refetchData();
                        this.withdraw_reason=""
                        // this.$toast({
                        //     component: ToastificationContent,
                        //     props: {
                        //         title: "Withdraw saved successfully",
                        //         icon: "BellIcon",
                        //         variant: "success",
                        //         text: res.data.message,
                        //     },
                        // });
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Withdraw saved successfully',
                                icon: 'BellIcon',
                                variant: 'success',
                                text: res.data.message,
                            },
                            }, {
                            timeout: 2000, 
                            position: 'bottom-center',
                            toastClassName:"radius-8 successful-info",
                            hideProgressBar : true,
                            });
                    } else {
                        // this.$toast({
                        //     component: ToastificationContent,
                        //     props: {
                        //         title: "withdraw Failed",
                        //         icon: "BellIcon",
                        //         variant: "danger",
                        //         text: res.data.message,
                        //     },
                        // });
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'withdraw Failed',
                                icon: 'BellIcon',
                                variant: 'danger',
                                text: res.data.message,
                            },
                            }, {
                            timeout: 2000, 
                            position: 'bottom-center',
                            toastClassName:"radius-8 error-info ",
                            hideProgressBar : true,
                            });
                    }
                })
                .catch((error) => {
                    console.log(error)
                    // this.$toast({
                    //     component: ToastificationContent,
                    //     props: {
                    //         title: "Withdraw already added",
                    //         icon: "BellIcon",
                    //         variant: "danger",
                    //         text: error.response.data.message,
                    //     },
                    // });
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Withdraw already added',
                            icon: 'BellIcon',
                            variant: 'danger',
                            text: error.response.data.message,
                        },
                        }, {
                        timeout: 2000, 
                        position: 'bottom-center',
                        toastClassName:"radius-8 error-info ",
                        hideProgressBar : true,
                        });
                });
        },

        confirmReview(data) {
            const self = this;

            self.itemUsername = data.item.user.name;
            self.itemDates = this.formatDatesArray(data.item.dates);

            self.itemHashId = data.item.hashid;
            self.itemReason = data.item.reason;
            self.reviewer_reason = data.item.reviewer_reason;
        },

        saveReview(status) {
            const self = this;
            const formData = new FormData();
            formData.append("reviewer_reason", self.reviewer_reason);
            formData.append("hashid", self.itemHashId);
            formData.append("status", status);
            self.$store
                .dispatch("app-leave_applications/saveReview", formData)
                .then((res) => {
                    if (res.data.success) {
                        this.refetchData();
                        // self.$toast({
                        //     component: ToastificationContent,
                        //     props: {
                        //         title: "Review saved successfully",
                        //         icon: "BellIcon",
                        //         variant: "success",
                        //         text: res.data.message,
                        //     },
                        // });
                        this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Review saved successfully',
                                    icon: 'BellIcon',
                                    variant: 'success',
                                    text: res.data.message,
                                },
                                }, {
                                timeout: 2000, 
                                position: 'bottom-center',
                                toastClassName:"radius-8 successful-info",
                                hideProgressBar : true,
                                });
                    } else {
                        // self.$toast({
                        //     component: ToastificationContent,
                        //     props: {
                        //         title: "Review Update Failed",
                        //         icon: "BellIcon",
                        //         variant: "danger",
                        //         text: res.data.message,
                        //     },
                        // });
                        this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Review Update Failed',
                                    icon: 'BellIcon',
                                    variant: 'danger',
                                    text: res.data.message,
                                },
                                }, {
                                timeout: 2000, 
                                position: 'bottom-center',
                                toastClassName:"radius-8 error-info ",
                                hideProgressBar : true,
                                });
                    }
                })
                .catch((error) => {
                    // self.$toast({
                    //     component: ToastificationContent,
                    //     props: {
                    //         title: "Oops! Review Update Failed",
                    //         icon: "BellIcon",
                    //         variant: "danger",
                    //         text: error,
                    //     },
                    // });
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Review Update Failed',
                            icon: 'BellIcon',
                            variant: 'danger',
                            text: error,
                        },
                        }, {
                        timeout: 2000, 
                        position: 'bottom-center',
                        toastClassName:"radius-8 error-info ",
                        hideProgressBar : true,
                        });
                });
            this.$refs["modalReviewApplication"].hide();
        },

        saveMassReview(status) {
            const self = this;
            const formData = new FormData();
            formData.append("reviewer_reason", self.reviewer_reason);
            formData.append("hashids", self.selectedLeaveApplications);
            formData.append("status", status);
            let data = {};
            data.url = "leave_applications/mass-update-status";
            data.params = formData;
            this.$store
                .dispatch("app/store", data)
                .then((res) => {
                    if (res.data.success) {
                        this.refetchData();
                        // self.$toast({
                        //     component: ToastificationContent,
                        //     props: {
                        //         title: "Review saved successfully",
                        //         icon: "BellIcon",
                        //         variant: "success",
                        //         text: res.data.message,
                        //     },
                        // });
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Review saved successfully',
                                icon: 'BellIcon',
                                variant: 'success',
                                text: res.data.message,
                            },
                            }, {
                            timeout: 2000, 
                            position: 'bottom-center',
                            toastClassName:"radius-8 successful-info",
                            hideProgressBar : true,
                            });
                    } else {
                        // self.$toast({
                        //     component: ToastificationContent,
                        //     props: {
                        //         title: "Review Update Failed",
                        //         icon: "BellIcon",
                        //         variant: "danger",
                        //         text: res.data.message,
                        //     },
                        // });
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Review Update Failed',
                                icon: 'BellIcon',
                                variant: 'danger',
                                text: res.data.message,
                            },
                            }, {
                            timeout: 2000, 
                            position: 'bottom-center',
                            toastClassName:"radius-8 error-info ",
                            hideProgressBar : true,
                            });
                    }
                })
                .catch((error) => {
                    // self.$toast({
                    //     component: ToastificationContent,
                    //     props: {
                    //         title: "Oops! Review Update Failed",
                    //         icon: "BellIcon",
                    //         variant: "danger",
                    //         text: error,
                    //     },
                    // });
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Oops! Review Update Failed',
                                icon: 'BellIcon',
                                variant: 'danger',
                                text: error,
                            },
                            }, {
                            timeout: 2000, 
                            position: 'bottom-center',
                            toastClassName:"radius-8 error-info ",
                            hideProgressBar : true,
                            });
                });
            this.$refs["modalReviewApplication"].hide();
        },

        confirmDeleteMultipleRecords() {
            this.$swal({
                title: "Are you sure?",
                text: `You want to Withdraw ${this.selectedLeaveApplications.length} Leave application, This cannot be undone.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, Withdraw it!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.$store
                        .dispatch(
                            "app-leave_applications/removeMultipleLeaveApplications",
                            this.selectedLeaveApplications
                        )
                        .then((res) => {
                            this.isDeleteMultipleCandChecked = false;
                            this.selectedLeaveApplications = [];
                            if (res.data.success) {
                                this.refetchData();
                                // this.$toast({
                                //     component: ToastificationContent,
                                //     props: {
                                //         title: "Deleted Successfully.",
                                //         icon: "BellIcon",
                                //         variant: "success",
                                //         text: res.data.message,
                                //     },
                                // });
                                this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: 'Deleted Successfully',
                                            icon: 'BellIcon',
                                            variant: 'success',
                                            text: res.data.message,
                                        },
                                        }, {
                                        timeout: 2000, 
                                        position: 'bottom-center',
                                        toastClassName:"radius-8 successful-info",
                                        hideProgressBar : true,
                                        });
                            } else {
                                // this.$toast({
                                //     component: ToastificationContent,
                                //     props: {
                                //         title: "Leave application Deletion failed.",
                                //         icon: "BellIcon",
                                //         variant: "danger",
                                //         text: res.data.message,
                                //     },
                                // });
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Leave application Deletion failed',
                                        icon: 'BellIcon',
                                        variant: 'danger',
                                        text: res.data.message,
                                    },
                                    }, {
                                    timeout: 2000, 
                                    position: 'bottom-center',
                                    toastClassName:"radius-8 error-info ",
                                    hideProgressBar : true,
                                    });
                            }
                        })
                        .catch((err) => console.log(err));
                }
            });
        },
        refetchLeaveApplicationsData() {
            console.log("Added Leave application");
        },
        deleteRecord(id) {
            const self = this;
            this.$store
                .dispatch("app-leave_applications/removeLeaveApplication", id)
                .then((res) => {
                    if (res.status == "204") {
                        // self.$toast({
                        //     component: ToastificationContent,
                        //     props: {
                        //         title: "Leave application Deleted",
                        //         icon: "BellIcon",
                        //         variant: "success",
                        //         text: "Leave application Deleted Successfully.",
                        //     },
                        // });
                        this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Leave application Deleted',
                                    icon: 'BellIcon',
                                    variant: 'success',
                                    text: 'Leave application Deleted Successfully'
                                },
                                }, {
                                timeout: 2000, 
                                position: 'bottom-center',
                                toastClassName:"radius-8 successful-info",
                                hideProgressBar : true,
                                });
                    } else {
                        // self.$toast({
                        //     component: ToastificationContent,
                        //     props: {
                        //         title: "Leave application Deletion failed.",
                        //         icon: "BellIcon",
                        //         variant: "danger",
                        //         text: res.data.message,
                        //     },
                        // });
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Leave application Deletion failed',
                                icon: 'BellIcon',
                                variant: 'danger',
                                text: res.data.message,
                            },
                            }, {
                            timeout: 2000, 
                            position: 'bottom-center',
                            toastClassName:"radius-8 error-info ",
                            hideProgressBar : true,
                            });
                    }
                    self.refetchData();
                })
                .catch((err) => {
                    // self.$toast({
                    //     component: ToastificationContent,
                    //     props: {
                    //         title: "Leave application Deletion failed.",
                    //         icon: "BellIcon",
                    //         variant: "danger",
                    //     },
                    // });
                                this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: `Leave application Deletion failed`,
                            icon:'BellIcon',
                            variant : 'danger',
                            hideClose: true,
                            
                        },
                            },
                            {
                        timeout : 3000,
                        position : 'bottom-center',
                        toastClassName:"error-info",
                        hideProgressBar : true,
                            })
                    console.error(err);
                });
        },
        openWindow(link) {
            window.open(link);
        },
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
