import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function LeaveApplicationList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: "select", sortable: false, label: "Select" },
        { key: "user.name", sortable: true, label: "Employee Name" },
        { key: "leave_type", sortable: true, label: "leave Type" },
        { key: "dates", sortable: false, label: "Dates" },
        { key: "duration", sortable: false, label: "Day Duration" },
        { key: "status", sortable: true, label: "Status" },
        { key: "action" }
    ];
    const perPage = ref(10);
    const totalLeaveApplicationList = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(null);
    const managerFilter = ref(null);
    const leaveApplicationsMetaData = ref([]);
    const leaveApplicationStatusOption = ref([]);
    const createdByFilter = ref(null);
    const leaveTypeFilter = ref(null);
    const leaveTypeOptions = ref([]);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalLeaveApplicationList.value
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch(
        [currentPage, perPage, searchQuery, statusFilter, createdByFilter, managerFilter, leaveTypeFilter],
        () => {
            refetchData();
        }
    );

    const fetchLeaveApplicationList = (ctx, callback) => {
        store
            .dispatch("app-leave_applications/fetchLeaveApplications", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                status: statusFilter.value,
                manager: managerFilter.value,
                createdBy: createdByFilter.value,
                leaveType: leaveTypeFilter.value
            })
            .then(response => {
                const leave_application = response.data.data;
                const { total } = response.data.meta;
                leaveApplicationStatusOption.value = response.data.status;

                callback(leave_application);
                totalLeaveApplicationList.value = total;
                leaveApplicationsMetaData.value = leave_application;
                leaveTypeOptions.value = response.data.leaveTypeOptions;
            })
            .catch(error => {
                console.error(error);
                // toast({
                //     component: ToastificationContent,
                //     props: {
                //         title: "Error fetching leave applications",
                //         icon: "AlertTriangleIcon",
                //         variant: "danger"
                //     }
                // });
                toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error fetching leave applications`,
                      icon:'AlertTriangleIcon',
                      variant : 'danger',
                      hideClose: true,
                      
                    },
                        },
                        {
                    timeout : 3000,
                    position : 'bottom-center',
                    toastClassName:"error-info",
                    hideProgressBar : true,
                        })
            });
    };
    

    const resolveUserStatusVariant = status => {
        if (status == 0) return "warning";
        if (status == 1) return "success";
        if (status == 2) return "danger";
        return "primary";
    };

    return {
        fetchLeaveApplicationList,
        tableColumns,
        perPage,
        currentPage,
        totalLeaveApplicationList,
        leaveApplicationsMetaData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        statusFilter,
        managerFilter,
        refetchData,
        resolveUserStatusVariant,
        leaveApplicationStatusOption,
        createdByFilter,
        leaveTypeOptions,
        leaveTypeFilter
    };
}
